<template>
    <div>
        <div v-if="! showRecieptPage && ! hasNotPaidMwanLevy">
            <hr class="my-4">
            <h2
                class="mb-0 text-uppercase text-center"
            >
                Add Payment to Transaction with number <br>
                <small class="text-danger">
                    {{ orderNumber }}
                </small>
            </h2>
            <hr class="my-4">

            <h3
                class="mb-0 text-uppercase text-center"
            >
                Required Amount :
                <b class="text-danger">
                    {{ (applicationSurcharge && applicationSurcharge.status ? applicationSurcharge.amount : applicationAmount) | formatPrice }}
                </b>
            </h3>

            <hr class="my-4" v-if="! showPaymentBlade">

            <div v-if="! hideVerifyRRR && ! isDirectPaymentPage">
                <h4 class="mb-0 text-danger">
                    Note: Read Carefully<br>
                    <ul>
                        <li>
                            <b class="text-primary">Generating RRR</b> - You can now generate
                            <em class="text-primary">RRR (Remita Retrieval Reference)</em> number and proceed to make payment offline through the designated banks. After
                            successful payment at the bank, you are advised to wait for 24 hours. Afterwards, you can now
                            proceed to add your payment to the portal. If you want to generate RRR for payment through designated
                            banks, kindly click on <strong class="text-primary">Generate RRR</strong> button.
                        </li><br>
                        <li>
                            <b class="text-primary">Adding Payment via RRR</b> - If you want to add payment to your
                            application via RRR, the RRR number must be a valid one (i.e. it must have to be paid and
                            not used before on the portal). Kindly click on
                            <strong class="text-dark">Add RRR</strong> button.
                        </li>
                    </ul>
                </h4>
                <hr class="my-4">
            </div>

            <div v-if="! hideVerifyRRR && isDirectPaymentPage">
                <h4 class="mb-0 text-danger">
                    Note: Read Carefully<br>
                    <ul>
                        <li v-if="showGeneratePage">
                            <b class="text-primary">Generating RRR</b> - You can now generate
                            <em class="text-primary">RRR (Remita Retrieval Reference)</em> number and proceed to make payment offline through the designated banks. After
                            successful payment at the bank, you are advised to wait for 24 hours. Afterwards, you can now
                            proceed to add your payment to the portal. If you want to generate RRR for payment through designated
                            banks, kindly click on <strong class="text-primary">Generate RRR</strong> button.
                        </li><br>
                        <li v-if="! showGeneratePage">
                            <b class="text-primary">Adding Payment via RRR</b> - If you want to add payment to your
                            application via RRR, the RRR number must be a valid one (i.e. it must have to be paid and
                            not used before on the portal). Kindly click on
                            <strong class="text-dark">Add RRR</strong> button.
                        </li>
                    </ul>
                </h4>
                <hr class="my-4">
            </div>

            <div class="text-center" v-if="! hideVerifyRRR && ! isDirectPaymentPage">
                <base-button
                    type="primary" size="lg"
                    @click="generatePayment()"
                >
                    <i class="fa fa-cogs"></i>
                    {{ isloading ? 'Please Wait' : 'Generate RRR' }}
                </base-button>

                <base-button
                    type="success" size="lg"
                    @click="showVerifyPage()"
                    :disabled="isloading"
                >
                    <i class="fa fa-credit-card"></i>
                    Add RRR
                </base-button>

                <base-button
                    type="danger" size="lg"
                    @click="removeAddPayment()"
                >
                    <i class="fa fa-times"></i>
                    Cancel
                </base-button>
            </div>

            <div class="text-center" v-if="! hideVerifyRRR && isDirectPaymentPage">
                <base-button
                    type="primary" size="lg"
                    @click="generatePayment()"
                    v-if="showGeneratePage"
                >
                    <i class="fa fa-cogs"></i>
                    {{ isloading ? 'Please Wait' : 'Click To Generate RRR' }}
                </base-button>

                <base-button
                    type="dark" size="lg"
                    @click="showVerifyPage()"
                    :disabled="isloading"
                    v-if="! showGeneratePage"
                >
                    <i class="fa fa-credit-card"></i>
                    Click To Add RRR
                </base-button>

                <base-button
                    type="danger" size="lg"
                    @click="removeAddPayment()"
                >
                    <i class="fa fa-times"></i>
                    Cancel
                </base-button>
            </div>

            <div v-if="hideVerifyRRR">
                <h4 class="mb-0 text-danger text-center" v-if="! showPaymentBlade">
                    Note: Kindly add all RRR payments that weren't generated from MDCN portal (i.e. RRR generated
                    from Remita Platform) on or before <em class="text-primary">31st August, 2020</em>. However, RRR
                    can now be generated from the portal and it is now only the valid means to generate payment for
                    MDCN portal, else any other means will not be accepted and will be forfeited.
                </h4>

                <hr class="my-4">

                <div class="row">
                    <div class="col-md-3 text-center"></div>
                    <div class="col-md-6 text-center" v-if="! showPaymentBlade">
                        <base-input alternative=""
                            label="Valid RRR Number"
                            placeholder="Enter Valid RRR Number"
                            input-classes="form-control-alternative"
                            type="number"
                            v-model="rrr"
                            v-on:keyup.enter="verifyPayment()"
                        />
                    </div>
                    <div class="col-md-3 text-center"></div>
                </div>


                <div class="text-center" v-if="! showPaymentBlade">

                    <base-button
                        type="success" size="lg"
                        @click="verifyPayment()"
                    >
                        <i class="fa fa-history"></i>
                        {{ isloading ? 'Please Wait' : 'Verify RRR' }}
                    </base-button>

                    <base-button
                        type="danger" size="lg"
                        @click="removeAddPayment()"
                    >
                        <i class="fa fa-times"></i>
                        Cancel
                    </base-button>
                </div>

                <div v-if="showPaymentBlade">
                    <h2 class="text-center">
                        Payment Details<br>
                        <small class="text-danger">
                            Kindly click on <em class="text-primary">Add Payment</em> button below to complete payment
                        </small>
                    </h2>


                    <hr class="my-4">

                    <h3 class="text-center">
                        Application Type: &nbsp;
                        <em class="text-capitalize font-weight-light">
                            {{ applicationName }}
                        </em>
                    </h3>
                    <h3 class="text-center">
                        Amount: &nbsp;
                        <em class="text-capitalize font-weight-light">
                            {{ paymentDetails.amount | formatPrice }}
                        </em>
                    </h3>
                    <h3 class="text-center">
                        Transaction Number: &nbsp;
                        <em class="text-capitalize font-weight-light">
                            {{ orderNumber }}
                        </em>
                    </h3>
                    <h3 class="text-center">
                        RRR Number: &nbsp;
                        <em class="text-capitalize font-weight-light">
                            {{ paymentDetails.RRR }}
                        </em>
                    </h3>
                    <h3 class="text-center">
                        Payment Date: &nbsp;
                        <em class="font-weight-light">
                            {{ paymentDetails.paymentDate | getDateTimeFormat }}
                        </em>
                    </h3>

                    <hr class="my-4">

                    <div class="text-center">
                        <base-button
                            type="success" size="lg"
                            @click="updatePayment()"
                        >
                            <i class="fa fa-credit-card"></i>
                            {{ isloading ? 'Please Wait' : 'Add Payment' }}
                        </base-button>
                        <base-button
                            type="danger" size="lg"
                            @click="removeAddPayment()"
                        >
                            <i class="fa fa-times"></i>
                            Cancel
                        </base-button>
                    </div>
                </div>
            </div>
        </div>

        <!-- generate RRR details template here -->
        <GeneratedRrr
            v-bind:record="generatedRrr"
            v-on:removeAddPayment="removeAddPayment"
            v-if="showRecieptPage && generatedRrr && ! hasNotPaidMwanLevy"
        />

        <MwanPayment
            :loadApplication="fetchUserProfile"
            v-if="hasNotPaidMwanLevy && ! showRecieptPage"
        />

    </div>

</template>

<script>
    import Vue from 'vue'
    import { mapActions, mapGetters } from 'vuex';
    import moment from 'moment';
    import swal from 'sweetalert2';// import sweet alert
    import VueClipboard from 'vue-clipboard2'
    import BTooltipDirective from 'bootstrap-vue/es/directives/tooltip'
    import GeneratedRrr from './GeneratedRrr';
    import MwanPayment from '../../../NewAccount/MwanPayment.vue';
    import axios from 'axios';

    Vue.use(VueClipboard)

    export default {
        name: 'AddPayment',
        props: ["orderNumber", "applicationName", "applicationAmount", 'serviceType', "applicationSurcharge", "license",
                "removeAddPayment", "app_id", "licenseYear", "applicationCode", "showGeneratePage", "isDirectPaymentPage",
                "exipryDate", "aqAmount"],
        components: {
            GeneratedRrr,
            MwanPayment
        },
        directives: {
            'b-tooltip': BTooltipDirective
        },
        data() {
            return {
                rrr: null,
                isloading: false,
                showPaymentBlade: false,
                paymentDetails: {},
                generate: {
                    service_type_id: null,
                    app_id: null,
                    amount: null,
                    user_data_id: null,
                    description: null,
                },
                hideVerifyRRR: false,
                showRecieptPage: false,
                generatedRrr: null
            }
        },
        filters: {
            getDateTimeFormat(value) {
                if(value) {
                    return moment(value).format('Do MMM, YYYY [at] h:mm:ss a');
                } else {
                    return 'N/A';
                }
            },
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+ ' NGN';
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['user']),
            hasNotPaidMwanLevy() {// if doctor is medical women and has not paid levy
                const mwan = this.user && this.user.basic_profile && this.user.basic_profile.mwan_levy ? this.user.basic_profile.mwan_levy : false;
                return mwan && mwan.status && ! mwan.pay_status;
            }
        },
        methods: {
            ...mapActions(['getRemitaPaymentStatus', 'updateApplicationTransaction', 'getOtherPayment', 'generatePaymentRRR', 'fetchUserProfile']),
            showVerifyPage() {
                return this.hideVerifyRRR = ! this.hideVerifyRRR;
            },
            checkForPayment() {// check for necessary payment
                this.getOtherPayment().then((res) => {// check if user can pass to make payment
                    this.isLoadingBlade = false;
                    if(res.status) {// redirect payment
                        return window.location.href='/new-application-payment/'+res.application.id;
                    }
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                });
            },
            verifyPayment() {
                let status = this.verifyRrrNumber();
                // console.log(status); 160352882748    250332191070  330204213948
                if(status) {
                    this.preloader();// show loading
                    this.isloading = true;
                    let data = { 
                        rrr : this.rrr, code : this.applicationCode, 
                        user_data_id : this.user.basic_profile.id,
                        is_url : true
                    };
                    this.getRemitaPaymentStatus(data).then((res) => {// get remita status
                        if(res.status) {// update transaction
                            this.verifyThroughRemita(res.url);
                        } else {
                            swal.fire('Payment Failed!', res.msg, 'warning');
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let message = 'An error occurred while verying payment, check your network and try again'; 
                        swal.fire('Network Failure!', message, 'warning');
                    });
                }
            },
            verifyThroughRemita(url) {
                axios.get(url).then((res) => {
                    this.isloading = false;
                    if(res.status == 200 && res.data) {
                        let check = res.data;
                        if((check.status == '00') || (check.status == '01')) {
                            this.paymentDetails = res.data;
                            this.showPaymentBlade = true;
                            swal.close();
                        } else {
                            let msg = 'Payment was not successful, kindly enter a valid RRR';
                            swal.fire('Payment Failed!', msg, 'warning');
                        }                        
                    }
                })
                .catch(() => {//check for failure
                    this.isloading = false;
                    let msg = 'Payment was not verified, kindly try again';
                    swal.fire('Payment Verfication Failed!', msg, 'warning');
                });
            },
            verifyRrrNumber() {
                if(this.rrr && this.rrr.length > 10) {
                    return true;
                } else if(this.rrr && this.rrr.length < 10) {
                    swal.fire("Incomplete RRR!", "Kindly check and enter a valid RRR", "warning");
                    return false;
                } else {
                    swal.fire("No RRR!", "Kindly enter a valid RRR, and try again", "warning");
                    return false;
                }
            },
            getYear(value) {
                return moment(value).format('YYYY');
            },
            updatePayment() {// update transaction
                this.isloading = true;
                let data = this.paymentDetails;
                let payDate = data.paymentDate ? data.paymentDate : data.transactiontime;
                let paymentYear = payDate ? this.getYear(payDate) : null;
                let currentlicenseYear = null;
                if(this.licenseYear) {// get license year
                    currentlicenseYear = this.licenseYear;
                } else if(this.license && this.license.license_year) {
                    currentlicenseYear = this.license.license_year
                }
                data.orderId = this.orderNumber;// add trans no
                data.license_year = currentlicenseYear ? currentlicenseYear : null;// add license year
                // console.log(currentlicenseYear, parseInt(paymentYear), (this.applicationAmount/2), data.amount);
                let total = this.applicationSurcharge && this.applicationSurcharge.amount ? this.applicationSurcharge.amount : this.applicationAmount;// get actual total amt
                let amountCheck = this.checkForRequiredAmount(total, data, (currentlicenseYear >=  parseInt(paymentYear)));// check for reqiured amount  301103284549
                if(currentlicenseYear && (currentlicenseYear >=  parseInt(paymentYear)) && ((total/2) <=  data.amount)) {// paid in previous year
                    this.makePaymentApiCall(data);
                } else if(currentlicenseYear && (currentlicenseYear - 1) <=  parseInt(paymentYear) && amountCheck) {// paid with surcharge amount
                    this.makePaymentApiCall(data);
                } else if(! currentlicenseYear && amountCheck) {// amount check for non-license payment
                    this.makePaymentApiCall(data);
                } else if(this.exipryDate && (this.aqAmount <= data.amount)) {// amount check for AQ payment
                    let check = this.exipryDate.isSameOrAfter(moment(payDate));
                    if(check) { // add payment
                        this.makePaymentApiCall(data);
                    } else {
                        let message = 'Amount on the RRR is less than the required amount';
                        swal.fire('Invalid Amount!', message, 'warning');
                    }
                } else if(total >= data.amount) {// Invalid Amount
                    this.isloading = false;
                    let message = 'Amount on the RRR is less than the required amount';
                    swal.fire('Invalid Amount!', message, 'warning');
                } else {
                    this.isloading = false;
                    let message = 'Amount on the RRR is less than the required amount,'+
                        'you have to pay the surcharge amount';
                    swal.fire('Invalid Amount!', message, 'warning');
                }
            },
            makePaymentApiCall(data) {// update transaction Api call
                this.preloader();// show loading
                this.updateApplicationTransaction(data).then((res) => {// update transaction
                    this.isloading = false;
                    if(res.status) {
                        swal.fire('Payment Added!', res.msg, 'success');
                        return window.location.href='/applications';
                    } else {
                        swal.fire('Payment Failed!', res.msg, 'warning');
                    }
                }).
                catch(() => {
                    this.isloading = false;
                    let message = 'An error while adding payment, kindly reload and try again';
                    swal.fire('Network Failure!', message, 'warning');
                });
            },
            checkForRequiredAmount(total, data, isPrevious) {// check for old payment function
                let status = false;
                let paymentDate = data.paymentDate ? data.paymentDate : null;
                let datePast = moment(paymentDate).isBefore(moment("2024-09-01T00:00:00.000Z"));// check if date is past September 1, 2024
                if(datePast && (this.applicationCode == 'cert-good-standing')) {// pass those are have paid before date
                    status = (data.amount >= 80000);
                } else {// normal check
                    status = this.passOldLicensePayment(total, paymentDate, isPrevious, data.amount);
                }
                return status;
            },
            passOldLicensePayment(total, paymentDate, isPrevious, amtPaid) {// pass the old amount for annual license for all payments that involves annual license 
                let minusAmt = 0;
                let oldPayDate = moment(paymentDate).isBefore(moment("2025-01-08T00:00:00.000Z"));// check if date is before January 7, 2024
                if(oldPayDate) {// pass the old amount for annual license for all payments that involves annual license 
                    let amount = isPrevious ? (parseInt(this.applicationAmount) / 2) : this.applicationAmount; // remove surcharge amount
                    if((this.applicationCode == "annual-license-old") || (this.applicationCode == "annual-license")) {
                        minusAmt = (this.applicationCode == "annual-license-old") ? 20000 : 10000; // minus increase amount
                    } else {
                        minusAmt = 20000; // minus increase amount
                    }
                    return (amount - minusAmt) <= amtPaid;
                } 
                return (total <= amtPaid); // return normal amount
            },
            generatePayment() {// generate RRR payment
                this.showRecieptPage = false;
                this.generatedRrr = null;
                this.emptyGenerateRRR();
                let description = this.applicationName ? 'Payment for '+ this.applicationName : '';
                description += description && this.license ? ' '+ this.license.license_year : '';
                let data = this.generate;
                this.isloading = true;
                data.service_type_id = this.serviceType.id;
                data.app_id = this.app_id;
                data.amount = this.applicationSurcharge && this.applicationSurcharge.amount ? this.applicationSurcharge.amount : this.applicationAmount;
                data.user_data_id = this.user && this.user.basic_profile ? this.user.basic_profile.id : null;
                data.description = description;
                if(this.isNotEmpty(data)) {
                    this.preloader();
                        this.generatePaymentRRR(data).then((res) => {// generate RRR
                        swal.close();// dismiss loading
                        this.isloading = false;
                        if(res.status) {
                            this.generatedRrr = res.rrr;
                            this.showRecieptPage = true;
                            this.showAlert(res.msg, 'success');
                        } else {
                            this.showAlert(res.msg, 'warning');
                        }
                    }).
                    catch(() => {
                        swal.close();// dismiss loading
                        this.isloading = false;
                        let type = 'warning';
                        let msg = 'An error occurred while generating RRR, kindly refresh page';
                        this.showAlert(msg, type);
                    });
                } else {
                    this.isloading = false;
                    let type = 'warning';
                    let msg = 'An error occurred while generating RRR, kindly refresh page';
                    this.showAlert(msg, type);
                }
            },
            isNotEmpty(obj) {// check for empty field
                return ! Object.keys(obj).some(k => !obj[k]);
            },
            emptyGenerateRRR() {// emtpy generate rrr
                this.generate.service_type_id = null;
                this.generate.app_id = null;
                this.generate.amount = null;
                this.generate.user_data_id = null;
                this.generate.description = null;
            },
            showAlert(msg, type) {
                this.$notify({
                    type: type,
                    title: msg
                })
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.fetchUserProfile();
        }
    }
</script>

<style scoped>
.right {
    float: right;
}
</style>
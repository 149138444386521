<template>
    <div>
        <div slot="header" class="bg-white border-0">
            <div class="row align-items-center">

                <div class="col-8">
                    <h3 class="mb-0 text-uppercase">{{ this.$route.name }}</h3>
                    <br>
                    <span class="text-danger">
                        Kindly enter a valid RRR (must have been paid for) and click on Proceed Now to fill /
                        continue with the Remediation Programme registration form
                    </span>
                </div>
                <div class="col-4 text-right">
                    <a :href="websiteUrl" class="btn btn-sm btn-danger text-white"> 
                        <i class="fa fa-reply"></i> Home
                    </a>
                </div>
            </div>
        </div>

        <div class="row" v-if='! showErrorStatus && ! isLoadingBlade'>
            <div class="col-lg-12">
                <hr>
            </div>
            <div class="col-lg-6">
                <base-input alternative=""
                    label="Practice Type"
                    required
                >
                    <select
                        class="form-control form-control-alternative"
                        v-model="type"
                    >
                        <option :value="null" disabled selected>--- Select One ---</option>
                        <option value="medical">Medical Practice</option>
                        <option value="dental">Dental Practice</option>
                    </select>
                </base-input>
            </div>
            <div class="col-lg-6">
                <base-input alternative=""
                    label="RRR"
                    placeholder="Enter Valid RRR"
                    input-classes="form-control-alternative"
                    v-model="rrr"
                    required
                />
            </div>
        </div>

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="col-lg-12 text-center" v-if='showErrorStatus && ! isLoadingBlade'>
            <base-alert :type="errorType">
                <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                <span class="alert-inner--text">
                    <h2 class="text-white">{{errorMsg}}</h2>
                </span>
            </base-alert>
        </div>

        <div v-if='! showErrorStatus && ! isLoadingBlade'>
            <hr>
            <router-link
                to="/remediation/programme"
                class="btn btn-danger"
            >
                <i class="fa fa-arrow-left"></i>
                Go Back
            </router-link>

            <base-button
                type="success"
                class="right"
                :disabled="! rrr"
                @click="getRRRStatus()"
            >
                {{ isloading ? 'Please Wait...' : 'Proceed Now'}}
                <i class="fa fa-arrow-right"></i>
            </base-button>
            <br><br><br><br>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'RemediationCheckPayment',
        data() {
            return {
                type: null,
                rrr: null,
                isloading: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: false
            }
        },
        computed: {
            ...mapGetters(['websiteUrl', 'loadingBladeUrl'])
        },
        methods: {
            ...mapActions(['getRemediationRrrStatus']),
            clearStorage() {
                localStorage.removeItem('applicantId');
            },
            emptyField() {
                this.type = null;
                this.rrr = null;
            },
            getRRRStatus() {
                if(this.rrr && this.type) {
                    this.isloading = true;
                    this.preloader();
                    let data = { rrr : this.rrr, option : 'check', type : this.type};
                    this.getRemediationRrrStatus(data).then((res) => {
                        this.isloading = false;
                        if(res.status) {
                            swal.close();
                            localStorage.setItem('applicantId', res.applicant_id);
                            return window.location.href = '/remediation/applicationForm';
                        } else {
                            this.isloading = false;
                            swal.fire("Verification Failed!", res.msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let msg = "Server Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                    });
                } else {
                    let msg = 'Kindly fill the necessary field(s) and try again...';
                    swal.fire("Empty field(s)!", msg, "warning");
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>